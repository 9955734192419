import "./styles.scss"

import React from "react"

import { Form } from "./components"

const SectionContact = () => {
  return (
    <section className="global-contact">
      <div className="container-fluid">
        <h2>Dowiedz się więcej</h2>
        <p>
          W Trust Investment Projekt 15 stawiamy na pełną transparentność.
          Zostaw dane - oddzwonimy.
        </p>
        <Form />
      </div>
    </section>
  )
}

export default SectionContact
