/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from "react"
import { toast } from "react-toastify"
import { graphql } from "gatsby"

import Layout from "components/Layout"
// import OurProjects from "components/OurProjects"
import SectionContact from "components/SectionContact"

import { Hero, About, Invest, News } from "page_components/home"

const Home = () => {
  const isBrowser = typeof window !== "undefined"
  const queryParameters = new URLSearchParams(
    isBrowser && window.location.search
  )
  const customerParam = queryParameters.get("customer")
  // const [filtered_projects, setFilteredProjects] = useState(
  //   data?.allWpPortfolio?.nodes
  // )

  useEffect(() => {
    customerParam === "verified" &&
      toast.success("Konto zostało pomyślnie aktywowane!")

    // setFilteredProjects(
    //   data?.allWpPortfolio?.nodes
    //     .filter(item => item.slug !== "stacja-kopernik")
    //     .slice(0, 2)
    // )
  }, [])

  return (
    <Layout isHome>
      <Hero />
      {/* <About /> */}
      {/* <Invest /> */}
      {/* <OurProjects data={filtered_projects} /> */}
      <News />
      <SectionContact />
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpPortfolio(sort: { fields: date, order: DESC }) {
      nodes {
        id
        title
        uri
        slug
        featuredImage {
          node {
            localFile {
              publicURL
            }
          }
        }
        acfPortfolio {
          city
          address
          area
          shortDescription
        }
      }
    }
  }
`

export default Home
